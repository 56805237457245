import { sdk } from "@gc/ipecs-web-sdk";
import debounce from "lodash.debounce";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Icon,
  Input,
  Popup as SUIPopup,
} from "semantic-ui-react";
import ringbacktone from "../../audio/ringbacktone.wav";
import { useAuth } from "../../contexts/AuthContext";
import { useDevices } from "../../contexts/DevicesContext";
import { useSoftphone } from "../../contexts/SoftphoneContext";
import { getFullPresenceDisplay } from "../../helpers/presence-helper";
import DialPad from "../DialPad";
import PresenceIndicatorDot from "../PresenceIndicatorDot";
import ActiveAudioCall from "../activeCall/ActiveAudioCall";
import ActiveCallActionBar from "../activeCall/ActiveCallActionBar";
import ActiveCallConnecting from "../activeCall/ActiveCallConnecting";
import ActiveCallContainer from "../activeCall/ActiveCallContainer";

const ACTION_BAR_HEIGHT = "80px";

const style = {
  remoteVideo: {
    width: "100%",
    height: "calc(100% - 100px)",
    zIndex: 2,
  },
  localVideo: {
    position: "absolute",
    right: "20px",
    bottom: "120px",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    zIndex: 3,
  },
};

function SearchBar({ disabled, handleChange }) {
  const { ipecsUser } = useAuth();
  const { user } = sdk;
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState([]);

  const handleSearchChange = async (e) => {
    setSearching(true);
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useRef(
    debounce(async (value) => {
      try {
        const searchParam = {
          term: value,
          field: "displayName,extensionNumber",
          scope: "all",
          limit: 8,
        };
        const _searchedUsers = (await user.searchUsers(searchParam)).filter(
          (x) => x.id !== ipecsUser.myInfo.id,
        );

        const newSearchedUsers = [];

        if (value.length > 0) {
          newSearchedUsers.push({
            key: "input-value",
            value: value,
            text: `${value}`,
          });
        }

        _searchedUsers.forEach((user) => {
          user.callNumbers.forEach((number) => {
            newSearchedUsers.push({
              key: user.id + number.value,
              value: number.value,
              text: `${user.name} | ${number.value}`,
              description: number.type,
              content: (
                <>
                  {user.type === "wuc" && (
                    <Popup
                      size="mini"
                      content={getFullPresenceDisplay(
                        user.presence,
                        user.statusMessage,
                      )}
                      trigger={
                        <PresenceIndicatorDot presence={user.presence} />
                      }
                    />
                  )}{" "}
                  {user.name} | {number.value}
                </>
              ),
            });
          });
        });

        setSearchedUsers(newSearchedUsers);
        setSearching(false);
      } catch (e) {
        //
      }
    }, 500),
  ).current;

  return (
    <Dropdown
      style={{ width: "300px" }}
      placeholder="Search or enter number"
      fluid
      search
      selection
      disabled={disabled}
      upward
      loading={searching}
      noResultsMessage={
        search.length <= 0 ? "Search or enter number" : "Searching"
      }
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      options={searchedUsers}
    />
  );
}

function ActiveSoftphoneCall() {
  const {
    minimized,
    remoteAudioRef,
    activeCall,
    consultationCall,
    conferenceCall,
    callAnimationRef,
    activeCallParties,
    transferNumber,
    handleCallAnswered,
    setCallDuration,
    numberInvalid,
  } = useSoftphone();
  const { audioOutput } = useDevices();

  const ringing = activeCall?.state === "calling" ?? false;
  const ringingRef = useRef();
  const [ringAudio, setRingAudio] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const acRing = activeCall && activeCall?.state === "calling";
      const ccRing = consultationCall && consultationCall?.state === "calling";
      const cfRing = conferenceCall && conferenceCall?.state === "calling";

      setRingAudio(acRing || ccRing || cfRing);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [activeCall, consultationCall, conferenceCall]);

  useEffect(() => {
    if (ringing && ringingRef.current) {
      ringingRef.current.setSinkId(audioOutput || "default");
    }
  }, [audioOutput, ringing]);

  useEffect(() => {
    remoteAudioRef.current.setSinkId(audioOutput || "default");
  }, [remoteAudioRef, audioOutput]);

  return (
    <ActiveCallContainer minimized={minimized}>
      <VideoElements />
      {ringing && (
        <ActiveCallConnecting
          callParty={activeCallParties[0]}
          invalid={numberInvalid}
          minimized={minimized}
        />
      )}
      {!ringing && activeCall?.mediaType === "audio" && (
        <ActiveAudioCall
          activeCall={activeCall}
          minimized={minimized}
          activeCallParties={activeCallParties}
          callAnimationRef={callAnimationRef}
          transferNumber={transferNumber}
          handleCallAnswered={handleCallAnswered}
          setCallDuration={setCallDuration}
        />
      )}
      <ActionBar />
      <audio
        ref={remoteAudioRef}
        muted={ringing && ringAudio && !numberInvalid}
      ></audio>

      {ringing && ringAudio && !numberInvalid && (
        <audio ref={ringingRef} src={ringbacktone} autoPlay loop></audio>
      )}
    </ActiveCallContainer>
  );
}

function VideoElements() {
  const { remoteVideoRef, localVideoRef, activeCall, activeCallParties } =
    useSoftphone();

  return (
    <>
      <video
        poster={
          activeCall?.mediaType === "video"
            ? `https://eu.ui-avatars.com/api/?name=${
                activeCallParties[0]?.name ?? ""
              }&size=500&rounded=true&background=#ccc&color=fff`
            : ""
        }
        style={style.remoteVideo}
        ref={remoteVideoRef}
        playsInline
        autoPlay
      ></video>

      <video
        style={style.localVideo}
        ref={localVideoRef}
        playsInline
        autoPlay
        muted
      ></video>
    </>
  );
}

function ActionBar() {
  const {
    minimized,
    setMinimized,
    activeCall,
    conferenceCall,
    consultationCall,
    handleHangup,
    handleConference,
    handleConferenceComplete,
    handleSendDtmf,
    handleToggleOnHold,
    muted,
    handleToggleMuted,
    videoMuted,
    handleToggleVideoMuted,
    blindTransferring,
    transferNumber,
    recordingStatus,
    handleToggleRecording,
  } = useSoftphone();

  const disconnecting = activeCall?.state === "disconnecting" ?? false;
  const onHold = activeCall?.state === "hold" ?? false;

  const [showTransferBox, setShowTransferBox] = useState(false);
  const [showDtmf, setShowDtmf] = useState(false);
  const [showParkCall, setShowParkCall] = useState(false);
  const [showConference, setShowConference] = useState(false);
  const [isConferenceable, setIsConferenceable] = useState(false);

  useEffect(() => {
    setIsConferenceable(
      activeCall?.consultationType === "conference" &&
        activeCall?.callUserInfo.length === 2,
    );
  }, [activeCall]);

  const hideActions = () => showTransferBox || showParkCall || showConference;

  return (
    <>
      {!minimized && showDtmf && (
        <div
          style={{
            position: "fixed",
            bottom: `calc(${ACTION_BAR_HEIGHT} + 30px)`,
            zIndex: 6,
          }}
        >
          <Card
            style={{
              width: "unset",
              paddingTop: "12px",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Card.Content>
              <DialPad
                onClick={(value) =>
                  handleSendDtmf(
                    value,
                    consultationCall?.ticket || activeCall?.ticket,
                  )
                }
              />
            </Card.Content>
          </Card>
        </div>
      )}

      <ActiveCallActionBar
        minimized={minimized}
        setMinimized={setMinimized}
        disconnecting={disconnecting}
        activeCall={activeCall}
        handleHangup={() => {
          handleHangup(activeCall.ticket);
        }}
        isConferenceable={isConferenceable}
        handleConferenceComplete={() => {
          handleConferenceComplete(activeCall);
          setShowConference(false);
        }}
        hideDefaultActions={hideActions()}
      >
        {showTransferBox && (
          <div style={{ display: "flex" }}>
            <TransferCall setShowTransferBox={setShowTransferBox} />
            <Popup
              content={"Keypad"}
              trigger={
                <Button
                  color={showDtmf ? "grey" : "black"}
                  onClick={() => setShowDtmf(!showDtmf)}
                  icon
                >
                  <Icon name="th" />
                </Button>
              }
            />
            {transferNumber.length === 0 && (
              <CancelButton
                tooltip="Cancel transfer"
                onClick={() => setShowTransferBox(false)}
                disabled={blindTransferring}
              />
            )}
          </div>
        )}

        {showParkCall && (
          <div style={{ display: "flex" }}>
            <ParkCall />
            <CancelButton
              tooltip="Cancel park call"
              onClick={() => setShowParkCall(false)}
            />
          </div>
        )}

        {showConference && (
          <div style={{ display: "flex" }}>
            <Conference
              conferenceCall={conferenceCall}
              handleConference={(conferenceNumber) => {
                setShowConference(false);
                handleConference(conferenceNumber);
              }}
            />
            <CancelButton
              tooltip="Cancel conference"
              onClick={() => setShowConference(false)}
            />
          </div>
        )}

        {!hideActions() && !isConferenceable && (
          <>
            <Popup
              content={onHold ? "Resume call" : "Hold call"}
              trigger={
                <Button
                  color="black"
                  disabled={
                    activeCall?.state !== "answered" &&
                    activeCall?.state !== "hold"
                  }
                  onClick={handleToggleOnHold}
                  icon
                >
                  <Icon name={onHold ? "play" : "pause"} />
                </Button>
              }
            />

            <Popup
              content={muted ? "Unmute" : "Mute"}
              trigger={
                <Button
                  color="black"
                  disabled={activeCall?.state !== "answered"}
                  onClick={handleToggleMuted}
                  icon
                >
                  <Icon name={muted ? "mute" : "unmute"} />
                </Button>
              }
            />

            {activeCall?.callNumber && activeCall.callNumber.length > 6 && (
              <Popup
                content={
                  recordingStatus.isRecording
                    ? "Stop recording"
                    : "Start recording"
                }
                trigger={
                  <Button
                    color={recordingStatus.isRecording ? "red" : "black"}
                    disabled={activeCall?.state !== "answered"}
                    onClick={handleToggleRecording}
                    icon
                  >
                    <Icon
                      name={
                        recordingStatus.isRecording
                          ? "circle"
                          : "circle outline"
                      }
                    />
                  </Button>
                }
              />
            )}

            {activeCall?.mediaType === "video" && (
              <Popup
                content={videoMuted ? "Enable my video" : "Disable my video"}
                trigger={
                  <Button color="black" onClick={handleToggleVideoMuted} icon>
                    <Icon name={videoMuted ? "hide" : "video"} />
                  </Button>
                }
              />
            )}

            <Popup
              content={"Transfer call"}
              trigger={
                <Button
                  color="black"
                  disabled={activeCall?.state !== "answered" || onHold}
                  onClick={() => {
                    setShowTransferBox(true);
                    setShowDtmf(false);
                    setShowParkCall(false);
                    setShowConference(false);
                  }}
                  icon
                >
                  <Icon name="exchange" />
                </Button>
              }
            />

            <Popup
              content={"Park call"}
              trigger={
                <Button
                  color="black"
                  disabled={activeCall?.state !== "answered"}
                  onClick={() => {
                    setShowTransferBox(false);
                    setShowDtmf(false);
                    setShowParkCall(true);
                    setShowConference(false);
                  }}
                  icon
                >
                  <Icon name="car" />
                </Button>
              }
            />

            {!isConferenceable && (
              <Popup
                content={"Add participant"}
                trigger={
                  <Button
                    color="black"
                    disabled={activeCall?.state !== "answered"}
                    onClick={() => {
                      setShowTransferBox(false);
                      setShowDtmf(false);
                      setShowParkCall(false);
                      setShowConference(true);
                    }}
                    icon
                  >
                    <Icon name="users" />
                  </Button>
                }
              />
            )}

            <Popup
              content={"Keypad"}
              trigger={
                <Button
                  color={showDtmf ? "grey" : "black"}
                  disabled={activeCall?.state !== "answered"}
                  onClick={() => setShowDtmf(!showDtmf)}
                  icon
                >
                  <Icon name="th" />
                </Button>
              }
            />
          </>
        )}
      </ActiveCallActionBar>
    </>
  );
}

function Popup({ ...props }) {
  return (
    <SUIPopup style={{ zIndex: 999999 }} position="top center" {...props} />
  );
}

function TransferCall({ setShowTransferBox }) {
  const {
    blindTransferring,
    handleBlindTransfer,
    transferNumber,
    handleTransfer,
    handleTransferComplete,
    handleTransferCancel,
  } = useSoftphone();

  const [transferTo, setTransferTo] = useState();

  const handleChange = (_, data) => {
    setTransferTo(data.value);
  };

  return (
    <div style={{ display: "flex" }}>
      {transferNumber.length > 0 ? (
        <>
          <Popup
            content={"Cancel transfer"}
            trigger={
              <Button
                color="purple"
                onClick={() => {
                  handleTransferCancel();
                  setShowTransferBox(false);
                }}
              >
                <Icon name="times circle" />
                Cancel Transfer
              </Button>
            }
          />

          <Popup
            content={"Confirm transfer"}
            trigger={
              <Button color="green" onClick={() => handleTransferComplete()}>
                <Icon name="circle check" />
                Confirm Transfer
              </Button>
            }
          />
        </>
      ) : (
        <>
          <SearchBar
            handleChange={handleChange}
            disabled={blindTransferring || transferNumber.length > 0}
          />
          <Popup
            content={"Confirm blind transfer"}
            trigger={
              <Button
                loading={blindTransferring || transferNumber.length > 0}
                disabled={
                  !transferTo || blindTransferring || transferNumber.length > 0
                }
                color="purple"
                onClick={() => handleBlindTransfer(transferTo)}
              >
                <Icon name="circle check" />
                Blind Transfer
              </Button>
            }
          />

          <Popup
            content={"Attended transfer"}
            trigger={
              <Button
                loading={blindTransferring || transferNumber.length > 0}
                disabled={
                  !transferTo || blindTransferring || transferNumber.length > 0
                }
                color="green"
                onClick={() => handleTransfer(transferTo)}
              >
                <Icon name="circle check" />
                Transfer
              </Button>
            }
          />
        </>
      )}
    </div>
  );
}

function ParkCall() {
  const [parkLocation, setShowParkLocation] = useState("");
  const { handleParkCall } = useSoftphone();

  const handleChange = (_, data) => {
    setShowParkLocation(data.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <Input
        maxLength={3}
        placeholder="Park location"
        value={parkLocation}
        onChange={handleChange}
      />

      <Popup
        content={"Confirm call park"}
        trigger={
          <Button
            disabled={parkLocation.length !== 3}
            color="green"
            onClick={() => handleParkCall(parkLocation)}
          >
            <Icon name="circle check" />
            Park Call
          </Button>
        }
      />
    </div>
  );
}

function Conference({ handleConference, conferenceCall }) {
  const [conferenceTo, setConferenceTo] = useState("");

  const handleChange = (_, data) => {
    setConferenceTo(data.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <SearchBar handleChange={handleChange} disabled={false} />

      <Popup
        content={"Start conference"}
        trigger={
          <Button
            disabled={
              conferenceTo.length < 3 || (conferenceCall ? true : false)
            }
            color="green"
            onClick={() => handleConference(conferenceTo)}
          >
            <Icon name="circle check" />
            Start conference
          </Button>
        }
      />
    </div>
  );
}

function CancelButton({ tooltip, onClick, disabled = false }) {
  return (
    <Popup
      content={tooltip}
      trigger={
        <Button color="black" onClick={onClick} disabled={disabled} icon>
          <Icon name="close" />
        </Button>
      }
    />
  );
}

export default ActiveSoftphoneCall;
